<template>
  <div class="tools02-home" v-if="tool.codeKebab !== ''">
    <el-row>
      <el-col>
        <h2>{{ tools[tool.codeCamel].name }}</h2>
      </el-col>
    </el-row>
    <el-row v-if="tool.codeKebab === 'tiktok-cv'">
      <el-col>
        <p style="color: #f15e62;font-weight: bold;">Squad beyondをお使いの方はLPタグと直LPリダイレクトタグを最新バージョンに差し替えお願いします。<br>
          【最新バージョン】LPタグ：v2.2、直LPリダイレクトタグ：v1.3</p>
      </el-col>
    </el-row>
    <div v-if="userData.displayStatusData[tool.codeCamel] === true">
      <el-row>
        <el-col>
          <SwitchTools02Active />
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <CartSystemContents v-if="tool.codeKebab === 'cart-system'" />
          <Contents v-else />
        </el-col>
      </el-row>
      <p></p>
    </div>
    <div v-else>
      <p style="text-align: center">現在、準備中です。</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SwitchTools02Active from "@/components/Tools02/Home/SwitchTools02Active.vue";
import Contents from "@/components/Tools02/Home/Contents.vue";
import CartSystemContents from "@/components/Tools02/Home/CartSystemContents.vue";

export default {
  components: {
    SwitchTools02Active,
    Contents,
    CartSystemContents,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "tools", "userData"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
:deep(h3) {
  margin-top: 50px;
}

:deep(h4) {
  margin-bottom: 5px;
}

:deep {
  .el-row {
    margin-top: 0;
  }

  .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border: solid 1px;
    border-radius: 0px 5px 5px 5px;
    margin-bottom: 20px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    .button-cover,
    .button-cover2,
    .button-cover3 {
      .el-button {
        padding: 0 15px;
        border: solid 1px #999;
        height: 54px;
        width: 100%;
        color: #777;
        font-weight: bold;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;

        img {
          margin-right: 5px;
        }

        .line {
          align-items: center;
          display: flex;
        }

        .el-icon-user-solid,
        .el-icon-s-order,
        .el-icon-s-tools,
        .el-icon-success,
        .el-icon-coin,
        .el-icon-refresh,
        .el-icon-present,
        .el-icon-document {
          font-size: 20px;
          padding-right: 5px;
        }
      }
    }

    .button-cover {
      width: 33%;

      @media screen and (max-width: 767px) {
        width: 80%;
        margin-bottom: 5px;
      }
    }

    .button-cover2 {
      width: 100%;

      @media screen and (max-width: 767px) {
        width: 80%;
        margin-bottom: 5px;
      }
    }

    .button-cover3 {
      width: 50%;

      @media screen and (max-width: 767px) {
        width: 80%;
        margin-bottom: 5px;
      }
    }

    .not-allowed {
      cursor: not-allowed;
      opacity: 0.5;
    }

    .initial {
      display: flex;
      align-items: center;
      justify-content: center;
      // margin: 20px auto;
    }

    .doble {
      display: flex;
      align-items: center;
      justify-content: center;
      // margin: 20px auto;
    }
  }

  .items-cover,
  .items-cover2 {
    display: flex;

    align-items: center;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    .item1 {
      width: 33%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .item2 {
      width: 66%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  .items-cover {
    justify-content: space-between;
  }

  .items-cover2 {
    justify-content: flex-start;
  }

  .sub-title,
  .sub-title2,
  .sub-title3 {
    box-sizing: border-box;
    background-color: #1c6db5;
    width: 20%;
    min-width: 212px;
    padding: 5px 0;
    text-align: center;
    border-radius: 5px 5px 0px 0px;
    color: white;
    margin-top: 0px;
    font-weight: bold;
  }

  .sub-title {
    background-color: #1c6db5;
  }

  .sub-title2 {
    background-color: #f15e62;
  }

  .sub-title3 {
    background-color: #5fa037;
  }

  a {
    text-decoration: none;
  }

  .yazirushi {
    font-size: 20px;
    font-weight: bold;
    margin-left: 1px;

    @media screen and (max-width: 767px) {
      transform: rotate(90deg);
    }
  }

  .margin {
    margin-right: 10px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
    }
  }
}
</style>
