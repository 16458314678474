<template>
  <div class="tools02-unit-detail-unit-editor">
    <el-row>
      <el-col :span="4">
        <a
          href="https://adtasukaru.wraptas.site/78b5e6e714bf4968adb2bc8b980e2498"
          target="_blank"
          rel="noopener noreferrer"
          >
          <Button2 type2="1" class="manual-button" msg="マニュアル" />
        </a>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="18">
        <table class="typeB">
          <tr>
            <th>
              <span>ユニット名</span>
            </th>
            <td>
              <el-input v-model="unitData.name"></el-input>
              <ValidationErrorMessages
                :errorMessageList="validationErrorData.name"
              />
            </td>
          </tr>
          <tr>
            <th>
              <span>一言メモ</span>
            </th>
            <td>
              <el-input v-model="unitData.memo"></el-input>
              <ValidationErrorMessages
                :errorMessageList="validationErrorData.memo"
              />
            </td>
          </tr>
          <tr>
            <th>
              <span>
                <span
                  v-if="
                    tool.codeKebab === 'facebook-cv' ||
                    tool.codeKebab === 'tiktok-cv'
                  "
                  >ピクセル</span
                >
                <span v-else-if="tool.codeKebab === 'line-cv'">LINE Tag</span>
                <span v-else-if="tool.codeKebab === 'yda-cv'"
                  >コンバージョン</span
                >
                を選択</span
              >
            </th>
            <td>
              <el-select
                v-model="unitData.tools02TagIdList"
                multiple
                placeholder="選択してください"
                style="width: 70%"
              >
                <template v-if="tool.codeKebab !== 'facebook-cv'">
                  <el-option
                    v-for="tagData in tagList"
                    :key="tagData.id"
                    :label="`${
                      tool.codeKebab === 'yda-cv'
                        ? tagData.tools02AccountData.accountName + ' | '
                        : ''
                    }${tagData.name}`"
                    :value="tagData.id"
                  >
                  </el-option>
                </template>
                <template v-else>
                  <el-option
                    v-for="pixel in pixels"
                    :key="pixel.pixelId"
                    :label="pixel.pixelName"
                    :value="pixel.pixelId"
                  >
                  </el-option>
                </template>
              </el-select>
              <ValidationErrorMessages
                :errorMessageList="validationErrorData.tagIdList"
              />
            </td>
          </tr>
        </table>
      </el-col>
    </el-row>
    <el-row>
      <el-col style="text-align: center">
        <el-button
          v-if="tool.codeKebab !== 'facebook-cv'"
          @click="register"
          type="primary"
          >登録</el-button
        >
        <el-button v-else @click="regUnitDetail" type="primary">登録</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";
import ValidationErrorMessages from "@/components/Ui/ValidationErrorMessages.vue";

export default {
  name: "Tools02UnitDetailUnitEditor",
  components: {
    Button2,
    ValidationErrorMessages,
  },
  props: {
    unitId: String,
  },
  data() {
    return {
      unitData: {
        name: "",
        memo: "",
        tools02TagIdList: [],
      },

      //
      validationErrorData: {
        name: [],
        memo: [],
        tagIdList: [],
      },
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "tools", "userData"]),
    ...mapGetters("tools02", ["pixels"]),
    tagList() {
      return this.$store.getters["tools02/allTagListInTool"](this.tool.id);
    },
  },
  watch: {
    unitId: {
      handler(newUnitId) {
        if (newUnitId !== "0") {
          // ====================== ↓↓ Facebook専用 ↓↓ ======================
          if (this.tool.codeKebab === "facebook-cv") {
            let unitData = JSON.parse(
              JSON.stringify(
                this.$store.getters["tools02/unitDetail"](newUnitId)
              )
            );

            //
            this.unitData = {
              name: unitData.unitName,
              memo: unitData.memo,
              tools02TagIdList: unitData.selectedPixels,
            };
            return;
          } else {
            // no action.
          }
          // ====================== ↑↑ Facebook専用 ↑↑ ======================

          //
          this.unitData = JSON.parse(
            JSON.stringify(this.$store.getters["tools02/unitData"](newUnitId))
          );
        } else {
          console.log("新規登録なので初期値のまま。");
        }
      },
      immediate: true,
    },
  },
  methods: {
    register() {
      let params = new URLSearchParams();
      params.append("unit_id", this.unitId);
      params.append("tool_id", this.tool.id);
      params.append("name", this.unitData.name);
      params.append("memo", this.unitData.memo);
      params.append(
        "tag_id_list",
        JSON.stringify(this.unitData.tools02TagIdList)
      );
      this.$axios
        .post(`${this.rt}/l/api/tools02/unit/register`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$store.dispatch("tools02/setUnitList");
            this.$router.go(-1);
          } else if (response.data.status === 400) {
            this.validationErrorData = response.data.validationErrorData;
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    regUnitDetail() {
      // 
      const unitData = this.$store.getters["tools02/unitDataByName"](this.unitData.name);
      console.log(unitData);
      if (unitData === undefined) {
        // ok!
      } else {
        if (this.unitId === unitData.unitId) {
          console.log("同じユニットの情報編集なのでOK!");
        } else {
          alert("同じユニット名がすでに登録されています。");
          return;
        }
      }
      
      // 
      let params = new URLSearchParams();
      params.append("unit_id", this.unitId);
      params.append("tool_id", this.tool.id);
      params.append("unit_name", this.unitData.name);
      params.append("memo", this.unitData.memo);
      params.append(
        "selected_pixels",
        JSON.stringify(this.unitData.tools02TagIdList)
      );
      this.$axios
        .post(`${this.rt}/api/tools02/regUnitDetail.php`, params)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.$store.dispatch("tools02/setUnits");
            this.$store.dispatch("setUserData");
            this.$router.go(-1);
          } else if (res.data.status === 400) {
            this.validationErrorData = res.data.validationErrorData;
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
