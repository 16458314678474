var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tools02-unit-detail-unit-editor"},[_c('el-row',[_c('el-col',{attrs:{"span":4}},[_c('a',{attrs:{"href":"https://adtasukaru.wraptas.site/78b5e6e714bf4968adb2bc8b980e2498","target":"_blank","rel":"noopener noreferrer"}},[_c('Button2',{staticClass:"manual-button",attrs:{"type2":"1","msg":"マニュアル"}})],1)])],1),_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',{attrs:{"span":18}},[_c('table',{staticClass:"typeB"},[_c('tr',[_c('th',[_c('span',[_vm._v("ユニット名")])]),_c('td',[_c('el-input',{model:{value:(_vm.unitData.name),callback:function ($$v) {_vm.$set(_vm.unitData, "name", $$v)},expression:"unitData.name"}}),_c('ValidationErrorMessages',{attrs:{"errorMessageList":_vm.validationErrorData.name}})],1)]),_c('tr',[_c('th',[_c('span',[_vm._v("一言メモ")])]),_c('td',[_c('el-input',{model:{value:(_vm.unitData.memo),callback:function ($$v) {_vm.$set(_vm.unitData, "memo", $$v)},expression:"unitData.memo"}}),_c('ValidationErrorMessages',{attrs:{"errorMessageList":_vm.validationErrorData.memo}})],1)]),_c('tr',[_c('th',[_c('span',[(
                  _vm.tool.codeKebab === 'facebook-cv' ||
                  _vm.tool.codeKebab === 'tiktok-cv'
                )?_c('span',[_vm._v("ピクセル")]):(_vm.tool.codeKebab === 'line-cv')?_c('span',[_vm._v("LINE Tag")]):(_vm.tool.codeKebab === 'yda-cv')?_c('span',[_vm._v("コンバージョン")]):_vm._e(),_vm._v(" を選択")])]),_c('td',[_c('el-select',{staticStyle:{"width":"70%"},attrs:{"multiple":"","placeholder":"選択してください"},model:{value:(_vm.unitData.tools02TagIdList),callback:function ($$v) {_vm.$set(_vm.unitData, "tools02TagIdList", $$v)},expression:"unitData.tools02TagIdList"}},[(_vm.tool.codeKebab !== 'facebook-cv')?_vm._l((_vm.tagList),function(tagData){return _c('el-option',{key:tagData.id,attrs:{"label":`${
                    _vm.tool.codeKebab === 'yda-cv'
                      ? tagData.tools02AccountData.accountName + ' | '
                      : ''
                  }${tagData.name}`,"value":tagData.id}})}):_vm._l((_vm.pixels),function(pixel){return _c('el-option',{key:pixel.pixelId,attrs:{"label":pixel.pixelName,"value":pixel.pixelId}})})],2),_c('ValidationErrorMessages',{attrs:{"errorMessageList":_vm.validationErrorData.tagIdList}})],1)])])])],1),_c('el-row',[_c('el-col',{staticStyle:{"text-align":"center"}},[(_vm.tool.codeKebab !== 'facebook-cv')?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.register}},[_vm._v("登録")]):_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.regUnitDetail}},[_vm._v("登録")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }